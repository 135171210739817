import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppGartnerReprintReprintGenAIDisclaimerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  .GartnerReprintDisclaimerBannerSection {
    display: flex;
    flex-direction: column; /* Stack content for better responsiveness */
    padding: 50px 20px; /* Add padding for mobile */
  }

  .GartnerReprintDisclaimerHeading {
    font-size: 1.2rem;
    color: #1f1f1f;
    letter-spacing: 1px;
    @media (min-width: 1650px) {
      font-size: 1.3rem;
    }
    @media (max-width: 1650px) {
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
    @media (max-width: 480px) {
      font-size: 42px;
    }
    @media (max-width: 1090px) {
      font-size: 1.2rem;
    }
  }

  .HomeDemoBannerPara {
    color: #5f5f5f;
    font-size: 0.9rem;
    @media (min-width: 1650px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1090px) {
      font-size: 0.9rem;
      padding-top: 8px;
    }
  }

  .GartnerReprintDisclaimerBannerContentSection {
    display: flex;
    flex-direction: column;
  }

  .GartnerReprintDisclaimerHeading,
  .HomeDemoBannerPara {
    margin: 0 auto;
    max-width: 1920px; /* Restrict text width for readability */
    padding-bottom: 10px;
  }
`

export const AppGartnerReprintReprintGenAIDisclaimer = () => {
  return (
    <AppGartnerReprintReprintGenAIDisclaimerWapper>
      <div className="GartnerReprintDisclaimerBannerSection">
        <div className="GartnerReprintDisclaimerBannerContentSection">
          <ReUseHOneTag Heading="Gartner Disclaimer:" HeadingStyle="GartnerReprintDisclaimerHeading" />
          <ReUsePtag
            para="Gartner, How Generative AI Progress Will Shape the Future of Procurement, October 2024"
            paraStyle="HomeDemoBannerPara "
          />
          <ReUsePtag
            para="GARTNER is a registered trademark and service mark of Gartner, Inc. and/or its affiliates in the U.S. and internationally and is used herein with permission. All rights reserved."
            paraStyle="HomeDemoBannerPara "
          />
        </div>
      </div>
    </AppGartnerReprintReprintGenAIDisclaimerWapper>
  )
}
